import React, {useEffect, useState} from 'react';
import './App.css';
import Form from "react-bootstrap/Form";
import Container from "@material-ui/core/Container";

function App() {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    useEffect(() => {
        const lines = input.split("\n");
        const newLines = lines.map(line => {
            const cols = line.split("\t");
            const newCols = cols.map(col => {
                let c = col
                    .replace(/%/g, '')
                    .replace(/,/g, '.')
                if (!c.match(/[a-z]/i)) {
                    console.log("No letter", c);
                    c = c.replace(/ /g, '');
                };
                if (c==='-') return '';
                return c;
            })

            return newCols;
        });

        console.log(newLines);
        let newData = newLines[0].map((col, i) => newLines.map(([...row]) => row[i]).join(","));
        console.log(newData);
        setOutput(newData.join("\n"))
    }, [input]);

    return (
        <div className="App">
            <Container maxWidth={"md"} className={"middle"}>
                <Form>
                    <Form.Group controlId="input">
                        <Form.Label>Paste from Excel</Form.Label>
                        <Form.Control as="textarea" rows="20" value={input}
                                      onChange={(event) => setInput(event.target.value)} className={"full"}/>
                    </Form.Group>
                    <Form.Group controlId="output">
                        <Form.Label>Paste into Highcharts</Form.Label>
                        <Form.Control as="textarea" rows="20" defaultValue={output} className={"full"}/>
                    </Form.Group>
                </Form>
            </Container>
        </div>
    );
}

export default App;
